.custom_container {
  padding: 20px 50px;
  max-width: 100%;
}

.mainBody {
  margin: 20px 0;
}

.categoryContainer {
  overflow: hidden;
  margin: 15px 0;
  background-color: #ecebeb66;
  /* border-radius: 10px; */
  /* box-shadow: 2px 4px 11px 5px #dddddd; */
}

.categoryContainer .titleContainer {
  padding: 15px 35px;
}

.titleName {
  color: #898989;
  text-align: start;
  font-weight: 600;
  font-size: 22px;
}

.titleLink {
  text-align: end;
}

.categoryContainer a {
  display: block;
  height: 100%;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #535353;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.categoryContainer a:hover {
  color: rgba(0, 0, 0, 0.801);
}

.categoryContainer .listPart {
  /* height: 48vh; */
  overflow-x: scroll;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 3px;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.categoryContainer .listPart .loading-card {
  width: 20%;
}

/* product container */
.prodContainer {
  /* border-radius: 10px;
  box-shadow: -1px 1px 16px 7px #cbcbcb2e; */
  border: 1px solid #d6d6d6;
  height: 100%;
  width: 20% !important;
  margin: 0 7px;
  padding: 7px;
  text-align: left;
  color: black;
  cursor: pointer;
  overflow: hidden;
}

.prodContainer:hover {
  box-shadow: 5px 5px 5px 0px #cbcbcb87;
  transform: scale(1.02);
}

.prodContainer img {
  height: 150px;
  object-fit: contain;
}

.prodContainer p {
  margin-bottom: 0.1rem;
}

.prodContainer .name {
  font-weight: 300;
  color: gray;
  display: block;
  line-height: 1.4rem;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3rem;
  margin-bottom: 0.35rem;
  -webkit-line-clamp: 2;
  /* white-space: nowrap; */
}

.prodContainer .prodPrice {
  font-size: 16px;
  font-weight: 700;
  color: #615f5f;
}

.prodContainer .prodRegPrice {
  color: #999999f7;
  text-decoration: line-through;
}

.prodContainer .prodPercOff {
  color: #ff0000a1;
  margin-left: 10px;
}

.prodContainer .star {
  height: 30px !important;
}

.prodContainer .btn {
  width: 100%;
  background-color: #f37b4c;
}

.prodContainer .product_left_actions {
  position: relative;
}

.prodContainer .product_left_actions a {
  display: flex;
  height: auto;
  position: absolute;
  right: 0;
  background-color: #ececec;
}

.toggleShowFilter {
  display: none;
}

.closeFilter {
  display: none;
}

/* category page */
.categoryPage {
  /* padding-top: 110px; */
  color: #717171;
  min-height: 100vh;
}

.categoryPage .categoryPageFilters {
  border-radius: 5px;
  margin: 8px;
  width: 30%;
  height: -moz-fit-content;
  padding: 15px;
}

.categoryPage .categoryPageFilters .subLvlCategLinks {
  display: block;
  font-size: 15px;
  margin-left: 10%;
  line-height: 1.7rem;
  color: #a7a7a7;
  font-weight: 400;
}

/* range slider */

.range-slider {
  height: 5px;
  position: relative;
  background-color: #e1e9f6;
  border-radius: 2px;
}
.range-selected {
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background-color: #1b53c0;
}

.range .range-slider-inputs {
  position: relative;
}

.range .range-slider-inputs input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.range-slider-inputs input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #1b53c0;
  background-color: #fff;
  pointer-events: auto;
  -webkit-appearance: none;
}
.range-slider-inputs input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid #1b53c0;
  background-color: #fff;
  pointer-events: auto;
  -moz-appearance: none;
}

.range .range-price-input {
  display: flex;
  margin: 25px 0 10px 0;
  justify-content: space-between;
}

.range .range-price-input input {
  padding: 5px;
  width: 40%;
  outline: none;
  border-radius: 10px;
  border: 1px solid;
}

.range button {
  margin: 15px;
  width: 80%;
}

/* search filter list */

.filter-list .itemsList {
  margin-top: 15px;
  max-height: 30vh;
  overflow: auto;
}

.listTile {
  margin: 3px;
}

.listTile input {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

/* category page products part */
.titleBar {
  display: flex;
  justify-content: space-between;
}

.titleBar .dropdown .btn {
  background-color: transparent;
  color: #848282;
  border: none;
}
.titleBar .dropdown .btn:hover {
  color: #13a8d3;
}

.titleBar .dropdown .btn:active {
  background-color: #d3d2d2;
  color: #666565;
}

.pageNumbersList {
  clear: both;
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

/* product Details */

.productDetails {
  /* padding-top: 110px; */
  color: #717171;
  min-height: 100vh;
}

.productDetails .row > * {
  text-align: left;
}

.productDetails .scrollProductsContainer {
  background-color: #ededed91;
  border-radius: 5px;
  box-shadow: 2px 3px 12px 2px #d9d9d99c;
  min-height: 60vh;
  padding: 15px;
  margin: 15px 0;
  text-align: left;
}

.productDetails .scrollProductsContainer > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.productDetails .mainDetails {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

/* .productDetails .row .pTitle {
  font-size: larger;
  font-weight: 600;
} */

.productDetails .scrollProductsContainer .pTitle {
  font-size: larger;
  font-weight: 600;
}

.pdetails .pName {
  font-size: xx-large;
}

.pdetails .pPrice {
  font-weight: bold;
  color: black;
  font-size: 24px;
}

.pdetails .pRegPrice {
  text-decoration: line-through;
}

.pdetails .pPercOff {
  color: #ff0000a1;
  margin-left: 10px;
}

.pdetails .p-details-add-cart {
  width: 100%;
  box-shadow: 2px 3px 5px silver;
  margin-top: 15px;
}

.pdetails .product_whatsapp {
  border: 1px solid black;
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 500;
}

.p-box {
  background-color: #ffffff8f;
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
}

.p-box li {
  line-height: 1.6rem;
}

.pListProducts {
  overflow-x: scroll;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 7px;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.pListProducts .prodContainer {
  height: 45vh;
  margin: 0 5px;
}

.pListProducts .loading-card {
  width: 20% !important;
  float: none;
}

/* catalog page */

.catalogPage {
  /* padding-top: 110px; */
  color: #717171;
  min-height: 100vh;
}

.catalogPage .catalogPageFilters {
  width: 30%;
}

/* cart page */
.cartPage {
  /* padding-top: 110px; */
  color: #717171;
  min-height: 100vh;
}

.cartPage .row {
  justify-content: space-between;
}

.cartPage .cartList {
  text-align: left;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 7px;
}

.cartPage .cartList .cartContainer {
  margin: 20px 0;
}

.loading-cart-image {
  height: 20vh;
  width: 100%;
  background-color: #eff0f0;
  border-radius: 10px;
}

.loading-cart-content-2 {
  background-color: #eff0f0;
  width: 90%;
  margin: 1rem 0;
}

.cartPage .cartList .cartContainer .cartDetails img {
  height: 20vh;
  width: auto;
  border-radius: 10px;
}

.loading-cart-content-3 {
  background-color: #eff0f0;
  width: 50%;
  margin: 1rem 0;
}

.cartPage .cartSummary {
  text-align: left;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 7px;
  height: fit-content;
}

.cartPage .cartSummary .btn {
  display: block;
  width: 100%;
}

/* about us */

.about-us {
  text-align: left;
  padding-top: 5px;
}

/* admin page */
.adminPage {
  padding: 20px;
  /* padding-top: 110px; */
  min-height: 100vh;
}

.adminPage .col-sm-4 {
  border-right: 1px dashed #bababa;
}

.adminPage .col-sm-4 .btn {
  display: block;
  background-color: transparent;
  color: black;
  border: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 0;
}

.admin_content_header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.admin_content_header .btn {
  border-radius: 18px;
  box-shadow: 3px 4px 6px silver;
}

.admin_content_content {
  margin-top: 15px;
  text-align: justify;
}

.admin_content_content .table tbody tr {
  cursor: pointer;
}

.adminPage form .mb-3 {
  /* display: flex;
  justify-content: space-between; */
  text-align: left;
}

.adminPage form .mb-3 .form-control {
  width: 80%;
}

.adminPage form .mb-3 .toggle_list_div {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  color: var(--bs-body-color);
  background-clip: padding-box;
  position: relative;
  z-index: 5;
}

.adminPage form .mb-3 .form-svg-display {
  height: 50px;
}

.adminPage form .form-image-display {
  width: fit-content;
  display: inline-block;
}

.adminPage form .form-image-display img {
  height: 150px;
  width: auto;
}

.adminPage form .mb-3 .toggle_list_btn {
  margin: 9px 1px;
  float: left;
  border-radius: 12px;
}

.adminPage form .mb-3 .toggle_list_btn:hover {
  background-color: var(--bs-btn-hover-bg) !important;
}

.adminPage form .mb-3 .dropdown {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  color: var(--bs-body-color);
  background-clip: padding-box;
}

.adminPage form .mb-3 .dropdown .dropdown-toggle {
  display: block;
  width: 75%;
  background-color: #00000021;
  color: #1d1d1d;
  border: 1px solid grey;
  margin: 5px 0;
}

.adminPage form .mb-3 .dropdown .dropdown-menu {
  width: 80%;
  margin-top: 5px;
  max-height: 200px;
  overflow: scroll;
}

.drop-down-menu-custom {
  display: none;
  max-height: 200px;
  overflow-y: scroll;
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.drop-down-item-custom {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.drop-down-item-custom:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.drop-down-item-custom:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.adminPage form .mb-3 .form-control-in-div {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.adminPage form .mb-3 .two-inputs {
  display: flex;
  justify-content: space-between;
}
.adminPage form .mb-3 .two-inputs .mb-3 {
  display: flex;
}

.adminPage form .mb-3 .two-inputs .form-control {
  width: 70%;
}

.adminPage form .mb-3 .two-inputs .btn {
  margin-left: 4px;
  height: 37px;
}

.adminPage form .mb-3 .form-control-in-div .input-group {
  width: 75%;
}

.adminPage form .mb-3 .form-control-in-div .input-group input {
  width: 70%;
}

.adminPage form .mb-3 .form-control-in-div .input-group .input-group-text {
  padding: 0;
  background-color: transparent;
  margin-left: 2px !important;
}
.adminPage form .form-submit-btn {
  width: 50%;
}

/* auth page */
.authpage {
  padding: 20px;
  /* padding-top: 110px; */
  min-height: 100vh;
}

.authpage .authform {
  text-align: left;
  padding: 20px;
  background-color: #e3e3e3;
  border-radius: 20px;
}

.authpage .authform > * {
  margin: 20px 7px;
}

.authpage .authform h2 {
  font-weight: 700;
}

.authpage .authform .form-control {
  line-height: 2.3rem;
}

.authpage .authform .btn {
  display: block;
  width: 70%;
  margin: auto;
  margin-top: 15px;
  height: 45px;
  font-size: 20px;
  background: green;
}

/* brand-component */
.brandcomponent h4 {
  font-size: 45px;
  font-style: oblique;
  font-family: emoji;
}

@media screen and (max-width: 992px) {
  /* category page */
  .pageFilters {
    width: 45% !important;
  }

  .customRow {
    flex-wrap: nowrap !important;
  }

  .customContainer {
    margin-left: 25px !important;
  }

  .pageProductsListing {
    width: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .categoryContainer {
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
  }
  .titleName {
    font-size: 17px;
  }

  .prodContainer .name {
    font-size: 14px;
  }

  .prodContainer .prodPrice {
    font-size: 13px;
    font-weight: 500;
  }

  .prodContainer .prodRegPrice {
    font-size: 14px;
  }

  .prodContainer .prodPercOff {
    font-size: 13px;
  }

  .categoryContainer .titleContainer {
    padding: 4px 8px;
  }

  .categoryContainer .titleContainer .titleLink svg {
    height: 14px;
  }

  .prodContainer {
    width: 33% !important;
  }
}

@media screen and (max-width: 481px) {
  .categoryContainer .listPart {
    /* height: 35vh; */
  }
  .titleContainer .col-sm-6 {
    width: 50%;
  }

  .prodContainer {
    width: 50% !important;
  }

  /* page filters */
  .toggleShowFilter {
    display: block;
  }

  .toggleShowFilter .btn {
    width: 100%;
    background-color: #b3f764;
    border: none;
    color: black;
  }

  .pageFilters {
    width: 95% !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: aliceblue;
    z-index: 99;
    margin: 0 !important;
    height: auto;
    overflow-y: auto;
    /* display: none; */
    opacity: 0;
    transform: translateX(-100%);
    transition: 1s ease;
  }

  .showFilter {
    /* display: block; */
    opacity: 1;
    transform: translateX(0);
  }

  .closeFilter {
    display: block;
    position: absolute;
    right: 5px;
  }

  .customRow {
    flex-wrap: nowrap !important;
  }

  .customContainer {
    margin-left: 0px !important;
  }

  .pageProductsListing {
    width: 100% !important;
    margin: 0;
  }

  .pageProductsListing .titleBar h3 {
    font-size: 14px;
  }

  .pageProductsListing .titleBar .dropdown .btn {
    font-size: 12px;
  }

  .pProductsListing .cList .prodContainer {
    width: 45% !important;
    height: 47vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .pProductsListing .cList .prodContainer .btn {
    width: 100%;
    display: block;
  }

  .prodContainer:hover {
    box-shadow: none;
    transform: none;
  }

  .pProductsListing .cList .prodContainer:hover .btn {
    width: 100%;
    display: block;
    position: relative;
    bottom: 0px;
  }

  .categoryContainer .listPart .loading-card {
    width: 55%;
  }
}
